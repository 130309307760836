<template>
    <div>
        <v-dialog v-model="deleteDialog" persistent max-width="602px" height="324px" @keydown="handleKeyDown">
            <v-card>
                <v-card-title class="d-flex justify-center align-items-center" style="background-color: #EB5757; font-size: 20px; font-weight: 500;">
                    <img :src="trashIcon" />
                </v-card-title>
                <v-divider />
                <!-- <v-card-text>
                <br />
                </v-card-text> -->
                <v-divider />

                <v-card-actions class="d-flex justify-center align-items-center flex-column" style="height:126px ">
                    <div class="ma-2" style="width: 336px; font-size: 24px; font-weight: 500; font-style: normal; color: #000000; line-height: 29.05px;width: 300px; word-wrap: break-word;">
                        Are you sure you want to delete this segment?
                    </div>
                    <div class="d-flex justify-center align-items-center ma-2" >
                        <div class="mr-8">
                            <v-btn
                            outlined
                            style="background-color: #ffffff; color: #EB5757; border: 1px solid #EB5757; width: 160px;"
                            @click="clickNo()"
                            >Cancel
                            </v-btn>
                        </div>
                        <div class="ml-8">
                            <v-btn style="background-color: #EB5757; color: #ffffff; width: 160px;" @click="clickYes()">
                                Yes, Delete
                            </v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
    props: ["confirmationFlag", 'itemType'],
    components: {
    },
    data() {
        return {
            publishGreenIcon: '',
            trashIcon: 'trashIcon.svg',
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            deleteDialog: false,
            dialogObject: {},
            action: "",
            workFlowId: "",
            dialogFileAttachments: [],
            showMessageDialog: false,
            errorMessageShow: false,
            errorMessage: "",
            errorMessageObject: {},
            okMessageObject: {},
        };
    },
    methods: {
        handleKeyDown(event) {
            if (event.keyCode === 27) {
                this.clickNo();
            }
        },
        closeMessageDialog(emitObject) {
          var closeDialogFlag = false;
          if (emitObject && emitObject.errorDisplayMessage) {
            closeDialogFlag = emitObject.errorDisplayMessage;
          }
          this.showMessageDialog = emitObject.flag;
          this.$emit("closeDialog", closeDialogFlag);
        },
        clickYes() {
            this.deleteDialog = false;
            this.$emit("closeDialog", {
                itemType: this.$props.itemType,
                value: true
            });
        },
        clickNo() {
            this.deleteDialog = false;
            this.$emit("closeDialog", {
                value: true
            });
        },
    },
    mounted() {
        this.trashIcon = this.cloudFront + 'trashIcon.svg';
        this.publishGreenIcon = this.cloudFront + 'publishGreenIcon.svg';
        this.deleteDialog = this.$props.confirmationFlag;
    },
};
</script>